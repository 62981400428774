
.bacground_img{
  background-image: url("../src/asset/img/blue.jpeg");
  object-fit: fill;
  
}
.bacground_1{

  background-color: rgb(255, 255, 255,0.1);
  color: rgb(247, 247, 247);
  font-size: 14px;
  font-weight: bold;
  height: 100vh;
  width: 102%;
  position: relative;
  right: 1%;
  
}
.img1{
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  text-align: center;
}
.fa{
  color: rgb(33, 148, 214);
}
.img2{
  height: 48px;
}